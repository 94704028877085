import Api from "../axios"
import UserInterface from "../models/user-interface"

const api = new Api()

export async function fetchUsers(): Promise<any> {
  try {
    const getUsers = await api.get({ path: "users" })
    //console.log({ getUsers })
    const { error } = getUsers
    if (error == null) {
      const { result } = getUsers
      return result
    } else {
      throw error
    }
  } catch (error) {
    console.error({ error })
  }
}

export async function getUserByIdReq(userId: string): Promise<any> {
  try {
    const fetchUser = await api.get({ path: `users/byId/${userId}` })
    const { error } = fetchUser
    if (error == null) {
      const { result } = fetchUser
      return result
    } else {
      throw error
    }
  } catch (error) {
    console.error({ error })
  }
}

export async function sendUserService({user}: { user: any }): Promise<any> {
  try {
    const createUser = await api.post({ path: "users/create", data: user })
    const {error} = createUser
    if(error === null){
      return true
    }else {
      return error
    }
  } catch (error) {
    console.error({error});
  }
}

export async function updateUserService({user, userId}:{user:UserInterface, userId: string}) {
  try {
    const updateUser = await api.post({path: `users/update-user/${userId}`, data: user})
    //console.log({updateUser});
    
    const {error} = updateUser
    if(error === null){
      return updateUser.result
    }else {
      throw error
    }
  } catch (error) {
    console.error({error});
    
  }
}

export async function siginReq(user: string, password: string): Promise<boolean | UserInterface | undefined> {
  try {
    const signinRequest = await api.post({path: "login/signin", data: {user, password}})
    const {error} = signinRequest
    if(error == null){
      const { result } = signinRequest
      return result
    }else {
      throw error
    }
  } catch (error) {
    console.error({error});
  }
}