import { Button, ButtonGroup, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, IconButton, Chip, Box, TableFooter } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useEffect, useRef, useState } from "react"
import { addCardToPreviewsAction, fetchCardsThunk, getCardResumeThunk, removeCardFromPreviewsAction, setCoordMapModalCardsListAction, setNewCardListSorted, updateCardsSortThunk } from "../cards-list.slice"
import { Add, Dangerous, Info, LocationOn, RemoveRedEye, VisibilityOff } from "@mui/icons-material"
import { CardInterface } from "../../../app/models/card-interface"
import React from "react"
import CardResumeComponent from "./card-resume-component"
import { setCardDatFromListAction, showPaymentModalAction } from "../../add-payment/add-payment.slice"
import { dateUTCToFriendly } from "../../../utils/date.utils"


export default function CardListComponent(){
  const { cards, emptyList, cardsAtPreview } = useAppSelector((state: RootState) => state.cardsList)
  const [ currentIndexDrop, setCurrentIndexDrop ] = useState<number>(-1)
  const [ overItemIndexDrop, setOverItemIndexDrop ] = useState<number>(-1)
  const [touchStartY, setTouchStartY] = useState<any>(null);

  const dispatch = useAppDispatch();

  const [rows, setRows ] = useState<CardInterface[]>([])

  const nSpans = 7
  useEffect(()=> {
    if(cards.length === 0 && !emptyList){
      dispatch(fetchCardsThunk())
    } 
    if(cards.length > 0) setRows(cards);
  }, [cards, emptyList])


  useEffect(() => {
  }, [overItemIndexDrop])

  const clickOnCard = (cardId: string) => {
    if(cardsAtPreview[cardId] === undefined){
      dispatch(addCardToPreviewsAction(cardId))
      dispatch(getCardResumeThunk(cardId))
    }else{
      dispatch(removeCardFromPreviewsAction(cardId))
    }
  }

  const determineRowColor = (card: CardInterface): string  => {
    if(card.status === 1){
      return "black"
    }else {
      return ""
    }
  }
  const [draggingRowIndex, setDraggingRowIndex] = useState<number>(-1);

  const handleTouchStart = (index: number) => (event: any) => {
    setTouchStartY(event.touches[0].clientY);
    setDraggingRowIndex(index);
  };

  const handleTouchMove = (index: number) => (event: any) => {
    const touchCurrentY = event.touches[0].clientY;

    if (draggingRowIndex !== null && Math.abs(touchCurrentY - touchStartY) > 30) {
      const rowsBelow = document.elementFromPoint(event.touches[0].clientX, touchCurrentY);
      if (rowsBelow && rowsBelow.nodeName === "TD") {
        const rowElement: any = rowsBelow.closest("tr");
        const newIndex = Array.from(rowElement.parentNode.children).indexOf(rowElement);
        handleDrop(newIndex)();
        setTouchStartY(touchCurrentY);
      }
    }
  };

  const handleTouchEnd = () => {
    setDraggingRowIndex(-1);
    setTouchStartY(null);
  };

  const handleDragStart = (index: number) => {
    setDraggingRowIndex(index);
  };

  const handleDragOver = (index: number) => (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => () => {
    const updatedRows = [...rows];
    const [draggedRow] = updatedRows.splice(draggingRowIndex, 1);
    updatedRows.splice(index, 0, draggedRow);
    setRows(updatedRows);
    const cardIds: string[] = updatedRows.map((el: CardInterface , i) => el._id!);
    dispatch(updateCardsSortThunk(cardIds))
    setDraggingRowIndex(-1);
  };

  return (
    <>
      <TableContainer sx={{maxWidth: '100%'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Nº</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Abono</TableCell>
              <TableCell align="center">Saldo</TableCell>
              <TableCell align="center">Ultimo Pago</TableCell>
              <TableCell align="center"> <LocationOn/> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {rows.map((el, i) => {
              const isCardAtPreview = cardsAtPreview[el._id!] !== undefined;              
              return (
                <React.Fragment key={el._id} >
                  {overItemIndexDrop === i && <TableRow> <TableCell colSpan={7}> Nueva Posicion </TableCell></TableRow>}
                  <TableRow  key={el._id} component={'tr'} 
                    onTouchStart={handleTouchStart(i)}
                    onTouchMove={handleTouchMove(i)}
                    onTouchEnd={handleTouchEnd}

                    onDragStart={() => handleDragStart(i)}
                    onDragOver={handleDragOver(i)}
                    onDrop={handleDrop(i)}                    
                    
                    draggable={true} data-id={el._id} data-index={i}
                  > 
                    <TableCell sx={{backgroundColor: determineRowColor(el)}} >{i+1}</TableCell>
                    <TableCell>
                      <ButtonGroup variant="contained">
                        <Button size="small" sx={{padding: "0px !important"}} color="info" onClick={() => clickOnCard(el._id!)}> 
                          {!isCardAtPreview ? <RemoveRedEye fontSize={"small"} /> : <VisibilityOff fontSize={"small"} />}   
                        </Button>
                        <Button size="small" sx={{padding: "0px !important"}} color="secondary"> <Info fontSize={"small"} />  </Button>
                        <Button size="small" sx={{padding: "0px !important"}} color="error"> <Dangerous fontSize={"small"} />  </Button>

                      </ButtonGroup>
                    </TableCell>
                    <TableCell>{el.name}</TableCell>
                    <TableCell align="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Chip label={el.todayPaymentsTotal ?? 0} />
                        <IconButton color="success" onClick={() => {
                          dispatch(showPaymentModalAction(true))
                          dispatch(setCardDatFromListAction(el))
                        }}> <Add /> </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell>{Number(el.total - (el.totalPayed ?? 0)).toFixed(2)}</TableCell>
                    <TableCell> {dateUTCToFriendly(el.date!.toString())} </TableCell>
                    <TableCell> <Button size="small" onClick={() => {
                      dispatch(setCoordMapModalCardsListAction({latitude: el.lat, longitude: el.lng}))
                    }}> <LocationOn fontSize="small" /></Button>  </TableCell>
                  </TableRow>
                  {isCardAtPreview && <TableRow>
                      <TableCell colSpan={nSpans}>
                        <CardResumeComponent cardData={cardsAtPreview[el._id!]} />
                      </TableCell>
                  </TableRow>}
                </React.Fragment>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              {/* <TableCell>N Pagos: {cards.reduce((total, current))}</TableCell> */}
              <TableCell colSpan={7}></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}
