import * as Yup from "yup"
import { FormikTouched, FormikValues, useFormik } from "formik"
import { Button, MenuItem, Select, TextField } from "@mui/material"
import { ReactNode, useEffect, useTransition } from "react"
import LoadingIndicator from "../../app/components/loading-indicator"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import {
  createUserThunk,
  fetchUserByIdThunk,
  removeCurrentUserAction,
  resetHandleUserStateAction,
  updateUserTnunk,
} from "./handle-user.slice"
import { useNavigate, useParams } from "react-router-dom"
import { fetchUsersThunk } from "../users-list/UserListSlice"
import { useTranslation } from "react-i18next"

const initialValues = {
  name: "",
  lastName: "",
  // email: "",
  user: "",
  phone: "",
  password: "",
  level: 1
}

export function HandleUser() {
  const { t } = useTranslation()
  const keyLabels: {[key: string] : string} = {
    "name": t("handle_user_view_name_label"),
    "lastName": t("handle_user_view_last_name_label"),
    "user": t("handle_user_view_user_label"),
    "phone": t("handle_user_view_phone_label"),
    "password": t("handle_user_view_password_label"),
  }

  const navigate = useNavigate()
  let { userId } = useParams()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Ingresa un nombre").min(4, "Minimo 6 caracteres"),
    lastName: Yup.string().required("Ingresa un apellido").min(4, "Minimo 6 caracteres"),
    // email: Yup.string()
    //   .min(4, "longitud minima de 4 ")
    //   .required("Email is required"),
    phone: Yup.string().min(6, "Minimo 7").required("Phone is required"),
    user: Yup.string().min(6, "Minimo 7").required("User is required"),
    password:
      userId !== undefined
        ? Yup.string().min(8, "Password should be of minimum 8 characters length")
        : Yup.string()
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
    level: Yup.number().required("Selecciona un nivel de usuario")
      
  })

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues,
    onSubmit: (values) => {
      if (userId === undefined) {
        dispatch(createUserThunk(formik.values))
      } else {
        dispatch(updateUserTnunk({dataUser: formik.values, userId}))
      }
    },
  })

  const dispatch = useAppDispatch()
  const { loading, currentUser, created } = useAppSelector(
    (state: RootState) => state.handleUser,
  )

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(fetchUserByIdThunk(userId as string))
    } else {
      dispatch(removeCurrentUserAction())
    }
  }, [userId])

  useEffect(() => {
    if (created === true) {
      navigate("/dashboard/user-list")
      dispatch(resetHandleUserStateAction())
      dispatch(fetchUsersThunk())
    }
  }, [created])

  useEffect(() => {
    if (currentUser !== undefined) {
      const { email, lastName, name, phone, level } = currentUser
      formik.setFieldValue("email", email)
      formik.setFieldValue("lastName", lastName)
      formik.setFieldValue("name", name)
      formik.setFieldValue("phone", phone)
      formik.setFieldValue("level", level)
    } else {
      formik.setFieldValue("email", "")
      formik.setFieldValue("lastName", "")
      formik.setFieldValue("name", "")
      formik.setFieldValue("phone", "")
      formik.setFieldValue("level", 1)
    }
  }, [currentUser])

  return (
    <>
      <LoadingIndicator open={loading} onClose={() => {}} />
      <form onSubmit={formik.handleSubmit}>
        {Object.keys(initialValues).map((key, i) => {
          const isPass = key === "password";
          if(key !== "level") {
            return (
              <TextField
                style={{ marginBlock: 10 }}
                fullWidth
                key={key}
                id={key}
                name={key}
                label={keyLabels[key].toString()}
                value={(formik.values as FormikValues)[key]}
                type={isPass ? "password" : "text"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean((formik.touched as FormikTouched<FormikValues>)[key]) &&
                  Boolean((formik.errors as FormikTouched<FormikValues>)[key])
                }
                helperText={
                  Boolean((formik.touched as FormikTouched<FormikValues>)[key]) &&
                  ((formik.errors as FormikTouched<FormikValues>)[
                    key
                  ] as ReactNode)
                }
              />
            )
          } else  {
            return (<Select key={key} name="level" label={key.toUpperCase()} value={(formik.values as FormikValues)[key]} onChange={formik.handleChange} onBlur={formik.handleBlur}  placeholder="Nivel usuario" fullWidth>
              <MenuItem value={0}>Admin</MenuItem>
              <MenuItem value={1}>Ruta</MenuItem>
            </Select>)
          }
        })}
        <Button sx={{marginTop: 1}} color="primary" variant="contained" fullWidth type="submit">  {t('handle_user_view_submit_button')} </Button>
      </form>
    </>
  )
}
