import { createTheme } from "@mui/material";

const DenseTheme = createTheme({
  components: {
    
    MuiOutlinedInput: {
      styleOverrides: {    
        input: {padding: '5px', marginTop: 2}, 
        //notchedOutline: {fontSizeAdjust: '2px'}
      }
      },
    MuiFormLabel: {styleOverrides: {root: {fontSize: '1rem', lineHeight: '1', transform: 'translate(5px, 10px) scale(1)'}}},
    MuiTableCell: { styleOverrides: { root: { padding: '1px', minWidth: "30px" } }},
    MuiButtonGroup: { defaultProps: { size: 'small'}},
    MuiButton: { defaultProps: { size: 'small'}, styleOverrides: { root: { padding: '2px', paddingLeft: '2px !important', paddingRight: '2px !important', minWidth: "30px", fontSize: '12px' } }},
    MuiIconButton: { defaultProps: { size: 'small'}},
    MuiSvgIcon: { defaultProps: { fontSize: "small" } , styleOverrides: { root: { fontSize: "16px" } } },
    MuiIcon: { defaultProps: { fontSize: 'small' } }
  }
})
export default DenseTheme