import * as Yup from "yup"
import { FormikTouched, FormikValues, useFormik } from "formik"
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material"
import { ReactNode, useEffect, useTransition } from "react"
import { getCurrenDateUtil } from "../../utils/date.utils"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store";
import { fetchUsersThunk } from "../users-list/UserListSlice";
import CardPositionMap from "./components/card-position-map";
import { MapOutlined } from "@mui/icons-material";
import { changeUserCreateCardAction, createCardThunk, setGeoPosAllowedHandleCardAction, setPositionHandleCardAction, setShowMapAction } from "./handle-card.slice";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import LoadingIndicator from "../../app/components/loading-indicator";
import { useNavigate } from "react-router-dom";
import { KhasWebConstants } from "../../app/khas-web-constants"
import { CheckUserAllowedComponent } from "../../app/components/check-user-allowed-component"
import { ThemeProvider } from "@emotion/react"
import DenseTheme from "../../app/theme/dense-theme"
import { useTranslation } from "react-i18next"


interface DisableInputI {
  [key: string]: boolean | undefined
}

const disabledInputs: DisableInputI = {
  date: true, total: true
}
const initialValues = {
  date: getCurrenDateUtil(),
  name: "",
  address: "",
  phone: "",
  ocupation: "",
  percentage: 20,
  value: 0,
  total: 0,
  dailyPayment: 0,
  nPayments:24, 
}

export function HandleCard() {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentUser} = useAppSelector((state: RootState) => state.login)
  const {users} = useAppSelector((state: RootState) => state.users)
  
  const labelLocale: {[key: string]: string} = {
    date: t("handle_card_view__date"),
    name: t("handle_card_view__name"),
    address: t("handle_card_view__address"),
    phone: t("handle_card_view__phone"),
    ocupation: t("handle_card_view__ocupation"),
    percentage: t("handle_card_view__percentage"),
    value: t("handle_card_view__value"),
    total: t("handle_card_view__total"),
    dailyPayment: t("handle_card_view__dailyPayment"),
    nPayments: t("handle_card_view__nPayments"),
  }

  const {geoPosAllowed, position, loading, createdCard, user} = useAppSelector((state: RootState) => state.handleCard)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Ingresa un nombre").min(4, "Minimo 4 caracteres"),
    address: Yup.string().required("Ingresa una direccion").min(4, "Minimo 4 caracteres"),
    phone: Yup.string().required("Ingresa un telefono").min(7, 'Minimo 7').required('Ingresa un telefono'),
    ocupation: Yup.string().required("Ingresa una ocupacion").min(4, "Minimo 4 caracteres"),
    percentage: Yup.number().min(0),
    value: Yup.number().required("Ingresa el valor del prestamo").min(0),
    total: Yup.number().min(0),
    dailyPayment: Yup.number().required().min(0),
    nPayments: Yup.number().required("Ingresa el numero de pagos").min(0)
  });

  useEffect(() => {
    if(users.length === 0){
      dispatch(fetchUsersThunk())
    }
  }, [users])

  useEffect(() => {
    if(navigator.geolocation){
      dispatch(setGeoPosAllowedHandleCardAction(true))
      navigator.geolocation.getCurrentPosition((pos) => {
        const {latitude, longitude} = pos.coords
        dispatch(setPositionHandleCardAction({latitude, longitude}))
      })
    }else {
      
    }
  }, [])

  const formik = useFormik({ 
    validationSchema:validationSchema,
    initialValues, 
    onSubmit: (values) => {      
      dispatch(createCardThunk({card: {...values, user}, lat: Number(position?.latitude), lng: Number(position?.longitude)}))
  } })

  const handleChange = ({key, value} : {key: string, value: string}) => {
    const allowToTrigger = ["percentage", "value", "dailyPayment", "nPayments"]
    if(allowToTrigger.indexOf(key) !== -1){
      var percentage =  Number(key === "percentage" ? value : formik.values.percentage);
      var val = Number(key === "value" ? value :  formik.values.value) 
      var interests = Number(val * (percentage/100));
      var dailyPayment = Number(key === "dailyPayment" ? value : formik.values.dailyPayment)
      var nPayments = Number(key === "nPayments" ? value : formik.values.nPayments)
      var total = Number(val) + Number(interests);
      if(key === "value" || key==="percentage") {
        formik.setFieldValue("nPayments", nPayments)
        formik.setFieldValue("dailyPayment", total/nPayments)
        formik.setFieldValue("total", total)
      }
      if(key === "dailyPayment"){
        formik.setFieldValue("nPayments", nPayments)
      }
      if(key === "nPayments"){
        formik.setFieldValue("dailyPayment", dailyPayment)
      }
    }
  }

  useEffect(() => {
    if(createdCard === true){
      navigate("/dashboard/cards-lists")
    }
  }, [createdCard])

  const handleUserSelectChange = (e: any) => {
    dispatch(changeUserCreateCardAction(e.target.value))
  }

  const render = (status: Status) => (<h1>{status}</h1>)
  
  return (
    <ThemeProvider theme={DenseTheme}>
      <CheckUserAllowedComponent>
        <LoadingIndicator open={loading}/>
        {currentUser !== undefined && <form
            onSubmit={formik.handleSubmit}
          > 
            <Grid container spacing={0.5}>
              {Object.keys(formik.values).map((key, i) => {
                // if(currentUser.level !== 0 && key === "user"){
                //   return <input key={key}  type="hidden" name={"user"} value={formik.values.user} onChange={formik.handleChange} />
                // }else {
                  return (
                    <Grid item xs={12} md={4} key={key}>
                      <TextField style={{marginBlock: 2}}
                        variant="outlined"
                        fullWidth
                        id={key}
                        name={key}
                        disabled={disabledInputs[key] !== undefined}
                        placeholder={labelLocale[key]}
                        label={labelLocale[key]}
                        value={(formik.values as FormikValues)[key]}
                        onChange={(e) => {
                          formik.handleChange(e)
                          handleChange({key: e.target.name, value: e.target.value})
                        }}
                        // onBlur={formik.handleBlur}
                        error={Boolean((formik.touched as FormikTouched<FormikValues>)[key]) && Boolean((formik.errors as FormikTouched<FormikValues>)[key])}
                        helperText={
                          Boolean((formik.touched as FormikTouched<FormikValues>)[key]) &&  ((formik.errors as FormikTouched<FormikValues>)[key]) as ReactNode
                        }
                      />
                    </Grid>
                  )

                // }
              })}
              {currentUser.level === 0 && <Grid item xs={12}>
                <Select sx={{width: '100%'}} label={t("handle_card_view_route_label")} required name="user" onChange={handleUserSelectChange} fullWidth value={user}> 
                  <MenuItem value={undefined}>-- RUTA -- </MenuItem>
                  {users.map((el, i) => <MenuItem key={el._id} value={el._id}>{el.user}</MenuItem>)}
                </Select>
              </Grid>}
              <Grid item xs={12} md={4} display={"flex"} alignItems={"center"} paddingBottom={"16px"}>
                <Button variant="contained" fullWidth startIcon={<MapOutlined />} onClick={()=> dispatch(setShowMapAction(true))}> UBICACION </Button>
              </Grid>
            </Grid>

            <Button color="primary" variant="contained" fullWidth type="submit">Submit</Button>
          </form>
        }
        {geoPosAllowed && position !== undefined && <Wrapper apiKey={KhasWebConstants.googleApiKey} render={render}>
          <CardPositionMap />
        </Wrapper>}
      </CheckUserAllowedComponent>
    </ThemeProvider>
  )
}

